import React from "react"
import ProjectLayout from "../components/projects/ProjLayout"
import Layout from "../components/Layout"
import Seo from "../components/SEO"

import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import GithubBtn from "../components/projects/GitHubBtn"
import Conclusion from "../components/projects/Conclusion"

const DetailTemplate = ({ data }) => {
  const project = data.contentfulDetailProject
  const description = data.contentfulProject.metaDesc.metaDesc
  const { showcasing, demostration, githubRepoLink, conclusion } = project
  return (
    <div>
      <Seo title={project.heroTitle} description={description} />
      <Layout>
        <ProjectLayout detailedProj={project}>
          <section className="flex flex-col py-10">
            {/* Demostration section */}
            {demostration !== null && (
              <div className="flex flex-col items-center justify-center my-10">
                <p className="text-tomato text-5xl font-semibold mb-5">
                  Demostration
                </p>
                <div className="flex flex-col md:flex-row">
                  {demostration.map((vid, idx) => {
                    const contentType = vid.file.contentType
                    return (
                      <video key={idx} width="250px" loop autoPlay muted>
                        <source src={vid.file.url} type={contentType} />
                      </video>
                    )
                  })}
                </div>
              </div>
            )}
            {/* Showcasing section */}
            {showcasing.map(e => {
              const title = e.title
              const photos = e.photos
              return (
                <div
                  key={title}
                  className="flex flex-col items-center justify-center py-5 mx-5"
                >
                  <div className="text-tomato text-4xl text-center font-semibold md:text-5xl">
                    {title}
                  </div>
                  <div className="pb-10">
                    {photos.map(photo => {
                      const subTitle = photo.title
                      const photoPath = getImage(photo)
                      return (
                        <div
                          key={subTitle}
                          className="flex flex-col py-5 text-center items-center"
                        >
                          <div
                            key={subTitle}
                            className="text-darkbeaver text-xl font-semibold"
                          >
                            {subTitle}
                          </div>
                          <GatsbyImage
                            image={photoPath}
                            alt={subTitle}
                            className="mx-20 my-10 drop-shadow-xl max-w-7xl"
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            {/* Github Link Repo */}
            <Conclusion conc={conclusion} />
            <GithubBtn github={githubRepoLink} />
          </section>
        </ProjectLayout>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query getSingleProject($slug__title: String) {
    contentfulDetailProject(slug: { title: { eq: $slug__title } }) {
      heroTitle
      heroSubtitle
      heroPhoto {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
      slug {
        title
      }
      role
      platform
      duration
      info {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      showcasing {
        title
        photos {
          title
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      demostration {
        file {
          url
          contentType
        }
      }
      githubRepoLink
      conclusion {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    contentfulProject(slug: { title: { eq: $slug__title } }) {
      metaDesc {
        metaDesc
      }
    }
  }
`

export default DetailTemplate
