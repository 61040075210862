import React from "react"
import { SiGithub } from "react-icons/si"
import { Button } from "../Button"

const GitHubBtn = ({ github }) => {
  return (
    <section
      data-sal="zoom-in"
      data-sal-duration="700"
      data-sal-easing="easeInOutSine"
      className="flex items-center justify-center py-10"
    >
      <a href={github} rel="noopener noreferrer" target="_blank">
        <Button title="Github">
          <SiGithub size={28} />
        </Button>
      </a>
    </section>
  )
}

export default GitHubBtn
