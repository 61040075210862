import React from "react"
import sanitize from "sanitize-html"

const conclusion = ({ conc }) => {
  console.log(conc)
  const title = conc.title
  const desc = conc.description.childMarkdownRemark.html
  const clean = sanitize(desc, {
    allowedTags: false,
    allowedAttributes: false,
  })
  return (
    <section
      className="flex flex-col justify-center items-center 
        space-y-10 py-10 px-10
        m-auto md:w-[800px] md:px-20"
    >
      <div className="text-4xl text-tomato md:text-5xl font-semibold">
        {title}
      </div>
      <div dangerouslySetInnerHTML={{ __html: clean }} />
    </section>
  )
}

export default conclusion
