import React from "react"
import { ButtonSlug } from "../Button"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import sanitizeHtml from "sanitize-html"

const query = graphql`
  {
    allContentfulProject {
      nodes {
        id
        visibility
        title
        slug {
          title
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`
const ProjLayout = ({ children, detailedProj }) => {
  const {
    allContentfulProject: { nodes: projects },
  } = useStaticQuery(query)
  const heroPhoto = getImage(detailedProj.heroPhoto)
  const detailSlug = detailedProj.slug.title
  return (
    <section className="bg-whisper">
      <div className=" flex flex-col pt-10 px-10 md:flex-row">
        <div
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="easeInOutSine"
          className="flex flex-col md:m-auto"
        >
          <p
            className="text-tomato font-semibold text-6xl mb-5 
            md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl whitespace-normal "
          >
            {detailedProj.heroTitle}
          </p>
          <p className="text-oxley text-xl first-letter:ml-1 md:text-2xl md:first-letter:ml-2 lg:text-3xl">
            {detailedProj.heroSubtitle}
          </p>
        </div>
        <GatsbyImage
          image={heroPhoto}
          alt={detailedProj.heroTitle}
          className="my-5 md:mx-20 md:w-[40%] self-center"
        />
      </div>
      <div className="flex text-beaver px-20 py-5 text-lg md:text-xl ">
        <div className="flex flex-col md:space-x-8 md:flex-row md:w-[100%] md:justify-between">
          {detailedProj.role !== null && (
            <p className="font-bold ">
              My Role:{" "}
              <span className="font-normal text-tomato">
                {detailedProj.role}
              </span>
            </p>
          )}
          <p className="font-bold ">
            Platform:{" "}
            <span className="font-normal text-tomato">
              {detailedProj.platform}
            </span>
          </p>
          <p className="font-bold ">
            Duration:{" "}
            <span className="font-normal text-tomato">
              {detailedProj.duration}
            </span>
          </p>
        </div>
      </div>
      <div className="bg-tomato text-whisper flex flex-col px-10 pt-10 items-center md:px-32 md:py-16 md:flex-row md:justify-between">
        {detailedProj.info.map(proj => {
          const text = proj.description.childMarkdownRemark.html
          const title = proj.title
          const clean = sanitizeHtml(text, {
            allowedTags: false,
            allowedAttributes: false,
          })
          return (
            <div
              key={title}
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-easing="easeInOutSine"
              className=" flex flex-col justify-center items-center space-y-5 mb-10 md:space-y-10 md:w-[40%]"
            >
              <h1 className="text-2xl md:text-3xl font-semibold">{title}</h1>
              <div className="text-justify">
                <div dangerouslySetInnerHTML={{ __html: clean }} />
              </div>
            </div>
          )
        })}
      </div>
      {children}
      <div className="flex flex-col bg-tomato px-5 py-10 space-y-10 ">
        {/* This portion below is for other projects to explore */}
        <section className="flex flex-col items-center">
          <p className=" text-whisper text-3xl">Other Projects to Explore</p>
        </section>
        {projects.map(project => {
          const image = getImage(project.image)
          const projSlug = project.slug.title
          if (projSlug !== detailSlug) {
            return (
              <section
                key={project.id}
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="easeInOutSine"
                className="flex flex-col items-center space-y-10 pb-10"
              >
                <p className="text-whisper text-xl">{project.title}</p>
                <ButtonSlug to={projSlug}>View More</ButtonSlug>
                <GatsbyImage
                  image={image}
                  alt={project.title}
                  className="mx-20 drop-shadow-3xl lg:mx-auto"
                />
              </section>
            )
          } else return null
        })}
      </div>
    </section>
  )
}

export default ProjLayout
